<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.solution')}}</h2>
    </div>

    <el-radio v-model="lang" label="zh-CN">中文</el-radio>
    <el-radio v-model="lang" label="en-US">English</el-radio>

    <el-table class="mt20" :data="list" border>
      <el-table-column type="index" label="#"></el-table-column>

      <el-table-column prop="date" :label="$t('form.title')">
        <template slot-scope="scope">
          <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
          <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="date" :label="$t('form.desc')">
        <template slot-scope="scope">
          <el-input v-if="lang == 'zh-CN'" v-model="scope.row.desc" size="small" clearable></el-input>
          <el-input v-else v-model="scope.row.en_desc" size="small" clearable></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="date" :label="$t('url')">
        <template slot-scope="scope">
          <el-input v-model="scope.row.url" size="small" clearable></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="date" :label="$t('image')">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              width="200"
              trigger="hover">
            <el-image :src="scope.row.image"></el-image>
            <el-input slot="reference" v-model="scope.row.image" size="small" clearable>
              <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload(scope.$index)"></el-button>
            </el-input>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column prop="date" :label="$t('action')">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" @click="remove(scope.$index)">{{$t('button.del')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs">{{$t('button.add')}}</el-button>

    <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>


    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import {updateOption, getOption} from '@/api/os/admin';

export default {
  data() {
    return {
      lang: 'zh-CN',
      dialogVisible: false,
      list: [],
      index: 0
    }
  },
  components: {
    selectImage
  },
  created() {
    this.get()
  },
  methods: {
    addAttrs() {
      this.list.push({
        title: '',
        desc: '',
        image: '',
        url: ''
      })
    },
    remove(index) {
      this.list.splice(index, 1)
    },
    mediaUpload(index) {
      this.dialogVisible = true
      this.index = index
    },
    getImages(data) {
      this.list[this.index].image = data
      this.dialogVisible = false
    },
    get() {
      getOption({
        name: 'page-solution',
      }).then(res => {
        this.list = res.data.data
      })
    },
    onSave() {
      updateOption({
        name: 'page-solution',
        value: this.list
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    }
  }
}
</script>